import React from 'react'
import Layout from "../../components/App/Layout"
import SEO from '../../components/App/SEO';
import Navbar from "../../components/App/Navbar"
import Footer from "../../components/App/Footer"
import {graphql, Link} from 'gatsby'
import LockPersonImg from '../../assets/images/ssl-tls/lock-person.svg'
import AwardImg from '../../assets/images/ssl-tls/award.svg'
import ProtectionImg from '../../assets/images/ssl-tls/protection.svg'
import HeartImg from '../../assets/images/ssl-tls/heart.svg'
import ArrowImg from '../../assets/images/ssl-tls/arrow.svg'

import SslImg from '../../assets/images/ssl-tls/ssl-tls-m.webp'
import SecurityImg from '../../assets/images/ssl-tls/security-standards-m.webp'
import EnhancedImg from '../../assets/images/ssl-tls/enhanced-privacy-m.webp'
import DedicationImg from '../../assets/images/ssl-tls/dedication-m.webp'

const SSL = ({data}) => {
    return (
        <Layout env={data.site.siteMetadata.env}>
            <SEO 
                postTitle='SSL/TLS and STARTTLS: Securing your communication channel | BlueMail App' 
                postDescription='BlueMail utilizes industry leading security protocols to exchange emails on a 2-way encrypted channel'
                postImage="/img/OG/og_image-security-center-ssl-tls.png"
                postURL='security/ssl-tls-starttls'
                postSEO
            />
            <Navbar />
            <div className='features-functions sslTls pt-120 pb-100'>
              <div className='container-fluid ssl-tls w-707 r'>
                <div className='container'>                        
                    <div className='row'>
                      <div className='col-lg-6 col-md-6 col-xs-12'>
                          <img className='svg' src={LockPersonImg} alt='Lock Person' />
                          <h2><span>SSL/TLS</span> -industry-leading encryption techniques</h2>
                          <p>At BlueMail, we take your security and privacy seriously when it comes to sending and receiving emails through the normal SMTP/IMAP protocols. These protocols, by default, may leave you susceptible to information theft as they lack inherent security measures. That's why we have implemented industry-leading encryption techniques, such as SSL/TLS and STARTTLS, to safeguard your email protocols.</p>
                      </div>                          
                    </div>
                </div>
              </div>
              <div className='container-fluid w-991'>
                <div className='row'>
                  <div className='col-lg-12 col-md-12 col-xs-12 mt-3 mb-5 p-0'>
                    <img src={SslImg} alt='SSL-TLS' />
                  </div>
                </div>
              </div> 
              <div className='container-fluid security-standards w-707 l'>
                <div className='container'>                        
                    <div className='row'>
                      <div className='col-lg-6 offset-lg-6 col-md-6 offset-md-6 col-xs-12'>
                        <img className='svg' src={AwardImg} alt='Award' />
                        <h2><span>security standards</span></h2>
                        <p>Furthermore, we are committed to staying at the forefront of security standards. We have recently made a significant transition to support TLS 1.2 and above, further strengthening the security of our systems. This upgrade ensures even more robust encryption for your email communications, providing you with enhanced protection.</p>
                      </div>                          
                    </div>
                </div>
              </div>
              <div className='container-fluid w-991'>
                <div className='row'>
                  <div className='col-lg-12 col-md-12 col-xs-12 mt-3 mb-5 p-0'>
                    <img src={SecurityImg} alt='Security' />
                  </div>
                </div>
              </div> 
              <div className='container-fluid enhanced-privacy w-707 r'>
                <div className='container'>                        
                    <div className='row'>
                      <div className='col-lg-6 col-md-6 col-xs-12'>
                          <img className='svg' src={ProtectionImg} alt='Protection' />
                          <h2><span>Enhanced</span> Privacy and Protection</h2>
                          <p>With BlueMail, you can rest easy knowing that all your sent and received data is always encrypted. We continuously strive to enhance security measures, and the adoption of TLS 1.2 and above is part of our ongoing commitment to your privacy and protection. Your email communications remain safe and private, shielded from prying eyes.</p>
                      </div>                          
                    </div>
                </div>
              </div>
              <div className='container-fluid w-991'>
                <div className='row'>
                  <div className='col-lg-12 col-md-12 col-xs-12 mt-3 mb-5'>
                    <img src={EnhancedImg} alt='Enhanced' />
                  </div>
                </div>
              </div>   
              <div className='container-fluid dedication w-707 l'>
                <div className='container'>                        
                    <div className='row'>
                      <div className='col-lg-6 offset-lg-6 col-md-6 offset-md-6 col-xs-12'>
                        <img className='svg' src={HeartImg} alt='Heart' />
                        <h2><span>BlueMail's</span> dedication</h2>
                        <p>Trust in BlueMail's dedication to your security and enjoy the peace of mind that comes with knowing your sensitive information is protected with the latest encryption technologies.</p>
                        <p>For additional information visit our security center </p>
                        <Link to='/security'>
                          <p style={{color: '#1F6BF1', background:'rgba(31, 107, 241, 0.1)', borderRadius: '6px', padding: '6px 20px 10px', width: 'fit-content'}}>Security | BlueMail App <img style={{margin: 'auto auto auto 5px'}} src={ArrowImg} alt='Arrow' /></p>
                        </Link>
                      </div>                          
                    </div>
                </div>
              </div>
              <div className='container-fluid w-991'>
                <div className='row'>
                  <div className='col-lg-12 col-md-12 col-xs-12 mt-3 mb-5 p-0'>
                    <img src={DedicationImg} alt='Dedication' />
                  </div>
                </div>
              </div>
            </div>
            <Footer />
        </Layout>
    )
}

export default SSL

export const query = graphql`
query SSLQuery {
  site {
      siteMetadata {
        title
        env
      }
    }
  }
`
